@font-face {
    font-family: "Webb Ellis Cup";
    font-weight: 900;
    src: url("../fonts/WebbEllisCup-Heavy.woff2") format("opentype");
}

@font-face {
    font-family: "Webb Ellis Cup";
    font-weight: 700;
    src: url("../fonts/WebbEllisCup-Bold.woff2") format("opentype");
}

@font-face {
    font-family: "Webb Ellis Cup";
    font-weight: 500;
    src: url("../fonts/WebbEllisCup-Regular.woff2") format("opentype");
}

@font-face {
    font-family: "Webb Ellis Cup";
    font-weight: 300;
    src: url("../fonts/WebbEllisCup-Light.woff2") format("opentype");
}