#onetrust-banner-sdk:focus,#onetrust-banner-sdk :focus,#onetrust-pc-sdk * {
    outline: none!important
}
#ot-sdk-btn-floating.ot-floating-button {
    bottom: .5rem!important;
    left: .5rem!important;
    animation: none!important;
    opacity: 1!important
}
#ot-sdk-btn-floating .ot-floating-button__front {
    background-image: url(../img/cookie_banner/cookie-ot.svg)!important;
    background-size: 80%!important;
    background-color: #2d3cff!important
}
#ot-sdk-btn-floating .ot-floating-button__back {
    background-color: #ff3000!important
}
#ot-sdk-btn-floating.ot-floating-button:after {
    font-size: 1.2rem!important;
    background: #2d3cff!important
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy {
    font-family: inherit!important;
    font-size: inherit!important;
    margin-top: 4rem!important
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc {
    color: #333!important;
    line-height: 1.3
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title,#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group {
    color: #19194b!important
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a {
    color: #2d3cff!important;
    text-decoration: none
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a:hover {
    text-decoration: underline
}
#onetrust-banner-sdk .banner-header {
    width: 10rem!important;
    height: 4rem!important;
    padding: .5rem 0 .5rem 2rem
}
#onetrust-banner-sdk .banner-header img {
    display: none
}
#onetrust-banner-sdk .banner-header:before {
    content: "";
    width: 100%;
    height: 100%;
    background: url(../img/cookie_banner/rwc2023-logo-horizontal-blue.svg) 50%/cover no-repeat;
    display: block
}
#onetrust-pc-sdk .ot-pc-logo {
    width: 7rem!important;
    height: 3rem!important;
}
#onetrust-pc-sdk .ot-pc-logo img {
    display: none
}
#onetrust-pc-sdk .ot-pc-logo:before {
    content: "";
    width: 100%;
    height: 100%;
    background: url(../img/cookie_banner/rwc2023-logo-horizontal-blue.svg) 50%/cover no-repeat
}
#onetrust-consent-sdk #onetrust-pc-btn-handler,#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
    color: #2d3cff!important;
    border-color: #2d3cff!important
}
#onetrust-banner-sdk #onetrust-reject-all-handler,#onetrust-consent-sdk #onetrust-accept-btn-handler,#onetrust-consent-sdk #onetrust-pc-sdk .ot-leg-btn-container .ot-active-leg-btn,#onetrust-consent-sdk #onetrust-pc-sdk button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn) {
    background-color: #2d3cff!important;
    border-color: #2d3cff!important
}